<template>
  <div class="url-shortener">
    <h2>URL Shortener</h2>
    <form @submit.prevent="shortenUrl">
      <input
          v-model="originalUrl"
          type="url"
          placeholder="Enter URL to shorten"
          required
      >
      <button type="submit">Shorten</button>
    </form>
    <div v-if="shortUrl" class="result">
      <p>Shortened URL:</p>
      <a :href="shortUrl" target="_blank">{{ shortUrl }}</a>
    </div>
    <p v-if="error" class="error">{{ error }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UrlShortener',
  data() {
    return {
      originalUrl: '',
      shortUrl: '',
      error: ''
    }
  },
  methods: {
    async shortenUrl() {
      try {
        const response = await axios.post(process.env.VUE_APP_API_URL,
            `url=${encodeURIComponent(this.originalUrl)}`,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
        );
        // Extract only the URL part from the response
        this.shortUrl = response.data.replace('Short URL: ', '').trim();
        this.error = '';
      } catch (error) {
        console.error('Error:', error);
        this.error = 'Error creating short URL';
        this.shortUrl = '';
      }
    }
  }
}
</script>

<style scoped>
.url-shortener {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

form {
  display: flex;
  margin-bottom: 20px;
}

input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.result {
  margin-top: 20px;
}

.error {
  color: red;
}
</style>
